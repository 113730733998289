import { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { Form, Submit } from "../Components/Form";
const Login = () => {
    const [cookies, setAuthKey, removeAuthKey] = useCookies([process.env.REACT_APP_AUTH_KEY]);
    const [user, SetUser] = useState({ grant_type: "password", username: "", password: "" });
    let HandleLogin = (e) => {
        e.preventDefault();
        fetchfunction();
    }
    let fetchfunction = async () => {
        var formBody = [];
        for (var property in user) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(user[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        fetch(process.env.REACT_APP_TOKEN_URL + "oauth/token", {
            method: "POST",
            body: formBody,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }).then(res => res.json())
            .then((result) => {
                if (result.access_token !== undefined) {
                    setAuthKey(process.env.REACT_APP_AUTH_KEY, result.access_token, { path: "/" });
                    window.location.href= "/";
                }
            },
                (error) => {
                    console.log(error);
                }
            );
    }
useEffect(()=>{
    removeAuthKey(process.env.REACT_APP_AUTH_KEY, { path: "/" })
}, []);

    const handleChange = e => {
        const { name, value } = e.target;
        SetUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    return (

        <div className="loginpage">
            <div className="col-md-3 login-container">
                <h5 className="text-center">Login</h5>
                <Form handler={HandleLogin} method="POST" >
                    <div className="form-label-group">
                    <input type="email" name="username" id="username" onChange={handleChange} required="required" className="form-control" placeholder="you @webmail.com" />
                    </div>
          
                 <div className="form-label-group mt-2">
                     <input type="password" name="password" onChange={handleChange} className="form-control" placeholder="*****" />
                 </div>

                 <div className="clearfix text-center py-2">
                 <Submit value="Login" />
                 </div>
                </Form>
            </div>
        </div>

    )
}
export default Login;