import { useEffect, useState } from 'react';
import useAuth from '../Components/AuthKey';
import { Card, CardBody, CardHeader } from '../Components/Card';
import { Table } from '../Components/Table';
import HttpCaller from '../Requester';
function Products() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
   const token = useAuth();

    function prodcutHandler() {
        if (this.readyState === 4) {
            if (this.status === 200) {
                setIsLoaded(true);
                setItems(JSON.parse(this.response));
            }

            else if (this.response == null && this.status === 0) {
                setIsLoaded(true);
                console.log("The computer appears to be offline.");
            } else {
                setIsLoaded(true);
                setError(this.error);
            }
        }
    }
    useEffect(() => {
        HttpCaller("GET", "Products/Get", false, token, prodcutHandler, null);
    }, [])


    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (

            <Card>
                <CardHeader> Products </CardHeader>
                <CardBody>
                    <Table>
                        <thead>
                            <tr>
                                <th>Id</th><th>Title</th><th>Code</th><th>Price</th><th>Category</th><th>Brand</th><th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, index) => { return (<tr key={index}><td>{item.id}</td><td>{item.title}</td><td>{item.code}</td><td>{item.price}</td><td>{item.categoryName}</td><td>{item.brandName}</td><td>{item.description}</td></tr>); })}
                        </tbody>
                        <tfoot><tr><td colSpan="6" className='text-right'><a href='/Addproduct'>+</a></td></tr></tfoot>
                    </Table>
                </CardBody>
            </Card>
        )
    }
}


export default Products;