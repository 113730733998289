
export default function HttpCaller(method, endpath, isAsync, token, handler, payload) {
    var req = new XMLHttpRequest();
    req.open(method, process.env.REACT_APP_SERVER_URL + endpath, isAsync);
    req.setRequestHeader("Authorization", "bearer " + token);
    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader("mode", "cors");
    req.withCredentials = true;
    req.onreadystatechange = (e) => {
        if (e.readyState === 4) {
            if (e.status === 401) {
                 window.location.href="/login";
            }
        }
    };
    req.onerror = (error) => {
        console.log(error.status);
    };
    req.addEventListener("load", handler);
    req.addEventListener("timeout", (t) => { console.log("timeout") });
    req.onabort=(event) => { console.log(event.status) };
    req.send(payload);
}

