import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import useAuth from './Components/AuthKey';
import AddProduct from "./Pages/AddProduct";
import ProBrands from "./Pages/Brands";
import Categories from "./Pages/Categories";
import Dashboard from './Pages/Dashboard';
import Inventory from './Pages/Inventory';
import Layout from "./Pages/Layout";
import Login from './Pages/Login';
import Products from "./Pages/Products";
import Purchase from './Pages/Purchase';
import PurchaseReport from './Pages/PurchaseReports';
import Sales from './Pages/Sales';
import SalesReport from './Pages/SalesReports';
import Stores from "./Pages/Stores";
import Transfer from './Pages/Transfer';
// var rou = [{path: "/Stores",element: <Stores/>},{path: "/Categories",element: <Categories/>},{path: "/Brands", element: <ProBrands/>}, {path: "/Products",    element: <Products/>}, {path:"/AddProduct", element:<AddProduct/>},{path: "/Purchase", element: <ProBrands/>},{path: "/",    element: <></>}];
// rou.push({path: "/",    element: <Login/>});

const router = createBrowserRouter([
{path:"login", element:<Login/>},
{path: "/Stores",element: <Stores/>},
{path: "/Categories",element: <Categories/>},
{path: "/Brands", element: <ProBrands/>},
{path: "/Products",    element: <Products/>}, 
{path: "/AddProduct", element:<AddProduct/>} ,
{path: "/Purchase", element: <Purchase/>}, 
{path: "/PurchaseReports", element: <PurchaseReport/>},
{path: "/Sales", element: <Sales/>},
{path: "/SalesReport", element: <SalesReport/>},
{path: "/Transfer", element: <Transfer/>},
{path: "/Inventory", element: <Inventory/>},
{path: "/",    element: <Dashboard/>}]);
function App() {
  const token = useAuth();
  if(token===undefined){
    return <Login/>
  }
  return (
    <Layout>
     <RouterProvider router={router} />
    </Layout>
  );
}
export default App;
