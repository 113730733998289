import $ from 'jquery';
import { useEffect, useRef, useState } from "react";
import useAuth from '../Components/AuthKey';
import { Card, CardBody } from "../Components/Card";
import { Select } from '../Components/Form';
import { Table } from "../Components/Table";
import HttpCaller from '../Requester';
const Inventory = () => {

    const [items, setItems] = useState([]);
    const [cats, setCats] = useState([]);
    const [brands, setBrands] = useState([])
    const [store, setStore] = useState([])
    const myselect = useRef();
    const [isLoaded, setIsloaded] = useState(false);
   const token = useAuth();
    const [query, setQuery]= useState({StoreId: "0", CategoryId: "0", BrandId: "0"})

    const handleChange = e => {
        const { name, value } = e.target;
        setQuery(prevState=>({
            ...prevState,
            [name]: value
        }));
    }

    function storeDataResponse() {
        if (this.readyState === 4) {
            if (this.status === 200) {
                setStore(JSON.parse(this.response));
            }
        }
    }
    function HandlebrandResponse() {
        if (this.readyState === 4) {
            if (this.status === 200) {
                setBrands(JSON.parse(this.response));
            }
        }
    }
    function HandleDataCatResponse() {
        if (this.readyState === 4) {
            if (this.status === 200) {
                setCats(JSON.parse(this.response));
                setIsloaded(true);
            }
        }
    }
    function handleRequestSearch(){
        if (this.readyState === 4) {
            if (this.status === 200) {
            setItems(JSON.parse(this.response));
            }
        } 
    }
function loadData(){
    HttpCaller("POST", "Inventory/SearchAsync", true, token, handleRequestSearch, JSON.stringify(query));
}
    useEffect(() => {
        HttpCaller("GET", "Stores/Get", false, token, storeDataResponse, null);
        HttpCaller("GET", "Brands/Get", false, token, HandlebrandResponse, null);
        HttpCaller("GET", "Categories/GetCategories", false, token, HandleDataCatResponse, null);
       $(document).ready(function(){
        console.log("hallow world");
       })
    }, [])
    if (isLoaded == false) {
        return <div>loading..</div>
    }
    else {
        return (
            <>
                <Card>
                    <div className="d-flex justify-content-between">
                        <div className="col-md-3 pl-0">
                            <Select name="StoreId" handler={handleChange}  items={store} >
                                <option value="0">--stores-- </option>
                            </Select>
                        </div>
                        <div className="col-md-3">
                            <Select name="BrandId" handler={handleChange}  items={brands} >
                                <option value="0">--Select Brand--</option>
                            </Select>

                        </div>
                        <div className="col-md-3">
                            <Select name="CategoryId" handler={handleChange}  items={cats} >
                                <option value="0">--Caregory--</option>
                            </Select>
                        </div>
                        <div className="col-md-3  pr-0">
                            <input type="button" onClick={loadData} value="Load Data" className="btn btn-sm btn-primary" />
                        </div>
                    </div>
                    <CardBody>
                        <Table>
                            <thead>
                                <tr><th>Code</th><th>Title</th><th>Brand</th><th>Category</th><th>Bal. Qty</th></tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => { return (<tr key={index}><td>{item.code}</td><td>{item.productTitle}</td><td>{item.brand}</td><td>{item.category}</td><td>{item.balanceQ}</td></tr>); })}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </>

        )

    }


}

export default Inventory;