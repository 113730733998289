import { useEffect, useState } from 'react';
import useAuth from '../Components/AuthKey';
import { Form, Select, Submit } from '../Components/Form';
const AddProduct = () => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [cats, setCats] = useState([]);
    const [brands, setBrands] = useState([]);
    const [product, setProduct] = useState({ Id: "0", Title: "", CategoryId: "", BrandId: "", Description: "", UnitPrice: "", Code: "" })
    const token = useAuth();
    let httpCaller = (method, endpath, isAsync) => {
        var req = new XMLHttpRequest();
        req.open(method, process.env.REACT_APP_SERVER_URL + endpath, isAsync);
        req.setRequestHeader("Authorization", "bearer " + token);
        req.setRequestHeader("Content-Type", "application/json");
        req.setRequestHeader("mode", "cors");
        req.withCredentials = true;
        return req;
    }

    useEffect(() => {
        var bran = httpCaller("GET", "Brands/Get", false);
        bran.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    setBrands(JSON.parse(this.response));
                }
            }
        };
        bran.send();
        var categories = httpCaller("GET", "Categories/GetCategories", false);
       
        categories.onreadystatechange = function () {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    setCats(JSON.parse(this.response));
                }
            }
        };
        categories.send();

    }, []);



    const HandleSubmit = (e) => {
        console.log("Form Submitted");
        let jsonData = product;
        e.preventDefault();
        fetch(process.env.REACT_APP_SERVER_URL + "Products/POST", {
            method: 'POST',
            body: JSON.stringify(jsonData)
        },
            function (res) {
                console.log(res);
                alert(res);
                window.location.href = '/Products';
            });
    }
    const handleChange = e => {
        const { name, value } = e.target;
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    return (
        <div className='col-md-8 offset-md-2'>
            <h4>Add new products</h4>
            <hr />
            <Form handler={HandleSubmit} method="POST">
                <div className='d-flex justify-content-between'>
                    <input type="text" name='Title' placeholder='Product titile' className='form-control' onChange={handleChange} />
                    <div className='col-md-3 d-flex px-0'>
                        <input type="text" name='Code' placeholder='Product Code' className='form-control' onChange={handleChange} />
                        <input type="number" name='UnitPrice' placeholder='Price' className='form-control' onChange={handleChange} />
                    </div>
                </div>
                <div className='d-flex mt-2'>
                    <div className='col-md-4 d-flex px-0'>
                        <Select name="BrandId" handler={handleChange} items={brands}></Select>
                        <Select name="CategoryId" handler={handleChange} items={cats}></Select>
                    </div>
                    <div className='col-md-8 pr-0'>
                        <textarea type="text" name='Description' placeholder='Description' className='form-control' onChange={handleChange} rows="2" cols="5"></textarea>
                    </div>
                </div>
                <div className='clearfix text-right mt-2'>
                    <Submit value="Save Product" />
                </div>
            </Form>
        </div>
    )
}
export default AddProduct;