import React, { useCallback, useEffect, useState } from 'react';
import useAuth from '../Components/AuthKey';
import { Card, CardBody, CardHeader } from '../Components/Card';
import { Submit } from '../Components/Form';
import { Table } from '../Components/Table';
import HttpCaller from '../Requester';
function ProBrands() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [Name, setName] = useState("");
    const [brandId, setbrandId] = useState(0);
    const [btnText, setBtnText] = useState("Save");
    const [reloadData, setReload] = useState(false);
    const token = useAuth();


    const checkReload = useCallback(() => {
        return reloadData;
    }, [reloadData]);


    function HandleSubmitRequest() {
        if (this.status === 200) {
            console.log(this.response);
        }
    };
    const handleSubmit = (e) => {
        let jsonData = { Name: Name, Id: brandId };
        e.preventDefault();
        setReload(true);
        HttpCaller("POST", "Brands/POST", false, token, HandleSubmitRequest, JSON.stringify(jsonData));
    };

    useEffect(() => {
        HttpCaller("GET", "Brands/get", false, token, HandleDataResponse, null);
    }, [checkReload]);

    let ClickedEdit = (index) => {
        console.log("fn = ClickedEdit");
        setName(items[index].name);
        setbrandId(items[index].id);
        setBtnText("Update");
    }
    function HandleDataResponse() {
        if (this.status === 200) {
            setIsLoaded(true);
            setItems(JSON.parse(this.response));
        }
        else if (this.status === 401) {
            return window.location.href = "/login";
        }
        else if (this.response == null && this.status === 0) {
            setIsLoaded(true);
            console.log("The computer appears to be offline.");
        } else {
            setIsLoaded(true);
            setError(this.error);
        }

    }



    if (error) {
        return <div> {error}</div>;
    } else if (!isLoaded) {
        return <div> Loading..</div>;
    } else {

        return (
            <>

                <Card>
                    <CardHeader>
                        Brands
                    </CardHeader>
                    <CardBody>
                        <Table>
                            <thead>
                                <tr><th>Id</th><th>	Name</th><th></th></tr>
                            </thead>
                            <tbody>
                                {items.length > 0 ? items.map((item, index) => { return (<tr key={index}><td>{item.id}</td><td>{item.name}</td><td><button className='btn btn-sm btn-success' onClick={e => (ClickedEdit(index))}>Edit</button><button className='btn btn-sm btn-danger'>Delete</button></td></tr>); }) : <tr><td colSpan="3">No items found</td></tr>}
                            </tbody>
                        </Table>
                        <form onSubmit={handleSubmit} method="POST" className="">
                            <div className='d-flex container-fluid'>
                                <div className='col-md-2'>
                                    <input type="hidden" value={brandId} onChange={e => setbrandId(e.target.value)} />
                                </div>
                                <div className='col-md-5'>
                                    <input type="text" className='form-control' placeholder='Type Brand Name' name="Name" value={Name} onChange={e => setName(e.target.value)} />
                                </div>
                                <div className='col-md-3'>
                                    <Submit value={btnText}></Submit>
                                &nbsp;
                                    <button type="button" className='btn btn-sm btn-danger' onClick={() => { setBtnText("Save"); setbrandId(0); }}>NEW</button>
                                </div>

                            </div>
                        </form>
                    </CardBody>
                </Card>


            </>
        );
    }
}

export default ProBrands;