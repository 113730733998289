import { useCookies } from 'react-cookie';
function Layout({ children }) {
    const [cookies, setAuthKey, removeCookie] = useCookies(process.env.REACT_APP_AUTH_KEY);

    const Logout = () => {
        removeCookie(process.env.REACT_APP_AUTH_KEY, { path: "/" });
    }



    return (
        <>

            <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
                <a className="navbar-brand col-md-3 col-lg-2 mr-0 px-3" href="#" title="A Simple Pos System">Simple PoS System</a>
            </nav>
            <div className="container-fluid">
                <div className="row">
                    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-dark sidebar collapse sidebar-menu">
                        <div className="sidebar-sticky pt-3">
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className="nav-link" href="/">Home </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/Stores">Stores </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/Categories">Categories </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/Brands">Brands </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" href="/Products">Products </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/Purchase">Purchase</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/Sales">Sales</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/inventory">Inventory </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/Transfer">Transfer</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/SalesReport">Sales Report</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/PurchaseReports">Purchase Report</a>
                                </li>
                                {cookies[process.env.REACT_APP_AUTH_KEY] !== undefined ? <li className="nav-item"><button type='button' className='btn btn-sm btn-default d-block text-light' onClick={Logout}>Logout  </button></li> : ""}
                            </ul>

                        </div>
                    </nav>
                    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-2 mt-3"> {children}</main>
                </div>
            </div>
        </>
    )
}

export default Layout;