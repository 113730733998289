export const Form = ({ children, handler, method }) => {
    return (
        <form onSubmit={handler} method={method} >{children}</form>
    )
}

export const Submit = (props) => {
    return (
        <input type="submit" value={props.value} className="btn btn-sm btn-outline-primary" />
    )
}

export const Select = (props) => {
    if(props.items === null){
        return null;
    }
    return (
        <select name={props.name} onChange={props.handler} multiple={props.multiple} className={`form-control ${props.select2 != undefined? ' js-select2' : ''} `}>
            {props.children}
            {props.items.length > 0? props.items.map((ca, index) => { return (<option key={index} value={ca.id}>{ca.name===undefined?ca.title : ca.name}</option>); }): <option>No Item found</option>}
        </select>
    )
}
