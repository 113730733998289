import { useCallback, useEffect, useState } from 'react';
import useAuth from '../Components/AuthKey';
import { Card, CardBody, CardHeader } from '../Components/Card';
import { Form, Submit } from '../Components/Form';
import { Table } from '../Components/Table';
import HttpCaller from '../Requester';
function Categories() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [category, setCategory] = useState({ Name: "", Id: "0" });
    const [btnText, setBtnText] = useState("Save");
    const [reloadData, setReload] = useState(false);
    const token = useAuth();
    function HandleSubmitRequest() {
        if (this.readyState === 4) {
            if (this.status === 200) {
                setCategory({ Name: "", Id: "0" });
                setBtnText("Save");
            }
        }
    }

    let handleSubmit = (e) => {
        let jsonData = category;
        e.preventDefault();
        setReload(true);
        HttpCaller("POST", "/Categories/POST", false, token, HandleSubmitRequest, JSON.stringify(category));
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setCategory(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const ClickedEdit = (index) => {
        console.log(items[index]);
        setCategory(prevState => ({
            ...prevState,
            "Name": items[index].name,
            "Id": items[index].id
        }));
        setBtnText("Update");
    }
    const reloadcheck = useCallback(() => {
        return reloadData;
    }, [reloadData]);
    function HandleDataResponse() {
        if (this.status === 200) {
            setIsLoaded(true);
            setItems(JSON.parse(this.response));
        }
        else if (this.status === 401) {
            return window.location.href = "/login";
        }
        else if (this.response == null && this.status === 0) {
            setIsLoaded(true);
            console.log("The computer appears to be offline.");
        } else {
            setIsLoaded(true);
            setError(this.error);
        }
    };
    useEffect(() => {
        HttpCaller("GET", "Categories/GetCategories", false, token, HandleDataResponse, null);
    }, [reloadcheck]);





    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <>
                <Card>
                    <CardHeader>
                        Categories
                    </CardHeader>
                    <CardBody>
                        <Table>
                            <thead>
                                <tr>
                                    <th scope="col">Id</th><th scope="col">Name</th><th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.length > 0 ? items.map((item, index) => { return (<tr key={index}><td>{item.id}</td><td>{item.name}</td><td><button className='btn btn-sm btn-success' onClick={e => (ClickedEdit(index))}>Edit</button>&nbsp;&nbsp;<button className='btn btn-sm btn-danger'>Delete</button></td></tr>); }) : <tr><td colSpan="3">No items found</td></tr>}
                            </tbody>
                        </Table>
                        <div className='clearfix'>
                            <Form handler={handleSubmit} method="POST">
                                <div className='d-flex'>
                                    <input type="hidden" name='Id' value={category.Id} onChange={handleChange} />
                                    <div className='col-md-5'>
                                        <input type="text" className='form-control' placeholder='Type Brand Name' name="Name" value={category.Name} onChange={handleChange} />
                                    </div>
                                    <div className='col-md-3'>
                                        <Submit value={btnText} /> &nbsp;&nbsp;
                                        {/* <input type="submit" value={btnText} className="btn btn-sm btn-primary" /> &nbsp; */}
                                        <button type="button" className='btn btn-sm btn-danger' onClick={() => { setBtnText("Save"); }}>Reset</button>
                                    </div>
                                </div>
                            </Form>
                        </div>

                    </CardBody>
                </Card>
            </>
        )
    }
}

export default Categories;