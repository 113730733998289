export const Table =({children})=>{

    return (
        <table className="table table-hover table-bordered">
            {children}
        </table>
    )
}

export const Thead =({children})=>{
    return (
        <thead>{children}</thead>
    )
}

export const Tbody =({children})=>{
    return (
        <tbody>{children}</tbody>
    )
}

export const Th=(text)=>{
    return <th>{text}</th>
}

export const row = ({children})=>{
    return (
        <tr>{children}</tr>
    )
    }