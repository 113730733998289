
import useAuth from '../Components/AuthKey';
import { Card, CardBody, CardHeader } from '../Components/Card';
import { Form, Select, Submit } from '../Components/Form';
import { Table } from '../Components/Table';
import HttpCaller from '../Requester';
const { useState, useEffect } = require("react");
const Transfer = () => {
    const [maxQ, setMaxQ] = useState("0");
    const [items, setItems] = useState([]);
    const [store, setStores] = useState([]);
    const [storesto, setStoresTo] = useState([]);
    const [isShow, setShow] = useState(false);
    const [item, setitem] = useState({ ProductId: "", FromStoreId: "", ToStoreId: "", Quantity: "" });
    const token = useAuth();
    function storesDataHandler() {
        if (this.readyState === 4) {
            if (this.status === 200) {
                setStores(JSON.parse(this.response));
            }
        }
    };
    useEffect(() => {
        HttpCaller("GET", "/Stores/Get", false, token, storesDataHandler, null);
    }, [token])
    function storeProductsHandle() {
        if (this.readyState === 4) {
            if (this.status === 200) {
                setItems(JSON.parse(this.response));
            }
        }
    }

    function ChangeSelection(e) {
        const { name, value } = e.target;
        HttpCaller("GET", "/Inventory/Get?StoreId=" + value, false, token, storeProductsHandle, null);
        setitem(prevState => ({
            ...prevState,
           [name]: value
        }));
        setStoresTo(store.filter(el=>el.id != value));
    }
    function handleTransferClick(e) {
        const {value} = e.target;
        var pro = items.find(f => f.productId == value);
        setMaxQ(pro.balanceQ);
        setShow(true);
        setitem(prevState => ({
            ...prevState,
            "ProductId": value,
            "Quantity" : pro.balanceQ
        }));
    }
    const handleChange = e => {
        const { name, value } = e.target;
        setitem(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
function HandleSubmitRequest(){
    if (this.readyState === 4) {
        if (this.status === 200) {
             isShow(false);
        }
    }
}

    function SubmitHandler(e) {
        e.preventDefault();
        HttpCaller("POST", "/Inventory/Transfer", true, token, HandleSubmitRequest, JSON.stringify(item));

    }
    function handleModalClose(){
        setShow(false);
    }
    return (
        <>
            <Card>
                <CardHeader> Stock Transfer to another Store</CardHeader>
                <CardBody>

                    <div className="col-md-8 offset-md-2">
                        <Select items={store} name="FromStoreId" handler={ChangeSelection}>
                            <option>--Select Store --</option>
                        </Select>
                    </div>



                    <Table>
                        <thead>
                            <tr>
                                <th>Code</th><th>Title</th><th>Brand</th><th>Category</th><th>Bal. Qty</th><th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, index) => { return (<tr key={index}><td>{item.code}</td><td>{item.productTitle}</td><td>{item.brand}</td><td>{item.category}</td><td>{item.balanceQ}</td><td>{item.balanceQ > 0 ? <button className='btn btn-sm btn-danger' onClick={handleTransferClick} value={item.productId}>Transfer</button> : ""}</td></tr>); })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>

            <div className={`modal fade ${isShow===true? "show d-block" : " "}`} id="transferModel" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="transferModelLabel"aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                    <Form handler={SubmitHandler} method="POST">
                        <div className="modal-header">
                            <h5 className="modal-title" id="productModalLabel">Transfer Product</h5>
                            <button type="button" className="close" onClick={handleModalClose}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                        
                                <label className="control-label">Transfer To Store</label>
                                <Select items={storesto} name="ToStoreId" handler={handleChange} >
                                    <option>--Select Dis. Store--</option>
                                </Select>
                                <label className="control-label mt-2">Quantity</label>
                                <input name="Quantity" type="number" min="1" max={maxQ} onChange={handleChange} value={item.Quantity} className="form-control" placeholder="quantity ex 10" />
                         
                        </div>
                    
                        <div className="modal-footer">
                            <button type="button" onClick={handleModalClose} className="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                       <Submit value="Transfer"/>
                        </div>
                        </Form>


                    </div>
                </div>
            </div>
            {isShow===true? <div className="modal-backdrop fade show"></div> : <div></div>}

            </>
     )
}
export default Transfer;