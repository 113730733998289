import {
  ArcElement, Chart as ChartJS, Legend, Title,
  Tooltip
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
  
  ChartJS.register(
    Title,
    ArcElement,
    Tooltip,
    Legend
  );
  
  export const options = {
    plugins: {
      title: {
        display: true,
        text: ' ',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
const  PieChart=({chartData, chartoptions})=>{
    return (
        <>
        <Pie data={chartData} options={chartoptions ?? options}></Pie>
        </>
    )
} 
export default PieChart;