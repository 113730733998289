import { useState } from 'react';
import useAuth from '../Components/AuthKey';
import Barchart from '../Components/Barchart';
import LineChart from '../Components/LineChart';
import PieChart from '../Components/PieChart';
import HttpCaller from '../Requester';
export default function Dashboard(){
  const token = useAuth();
 const [mydata, setData] = useState([]);
 const [catProducts, setCatsProducs] = useState([]);
 const [brandsProducts, setBrandsProducts] = useState([]);
 const [catsSales, setCatsSales] = useState([]);
 const [catsPurchase, setCatsPurchase] = useState([]);
 const [brandSales, setBrandSales] = useState([]);
 const [brandPurchase, setBrandPurchase] = useState([]);
function handleData(){
  if (this.readyState === 4) {
    if (this.status === 200) {
       setData(JSON.parse(this.response));
    }
  else if(this.status ===401){
    window.open("/login");
  }
}
}

function catphandleData(){
  if (this.readyState === 4) {
    if (this.status === 200) {
      setCatsProducs(JSON.parse(this.response));
    }
}
}
function brandphandleData(){
  if (this.readyState === 4) {
    if (this.status === 200) {
      setBrandsProducts(JSON.parse(this.response));
    }
}
}
function catsSaleshandleData(){
  if (this.readyState === 4) {
    if (this.status === 200) {
      setCatsSales(JSON.parse(this.response));
    }
}
}
function catsPurchasehandleData(){
  if (this.readyState === 4) {
    if (this.status === 200) {
      setCatsPurchase(JSON.parse(this.response));
    }
}
}
function brandSaleshandleData(){
  if (this.readyState === 4) {
    if (this.status === 200) {
      setBrandSales(JSON.parse(this.response));
    }
}
}
function brandPurchasehandleData(){
  if (this.readyState === 4) {
    if (this.status === 200) {
      setBrandPurchase(JSON.parse(this.response));
    }
}
}

useState(()=>{
  HttpCaller("GET", "/Inventory/DashboardData", false, token, handleData, null);
  HttpCaller("GET", "/Inventory/CategoryProducts", false, token, catphandleData, null);
  HttpCaller("GET", "/Inventory/BrandProducts", false, token, brandphandleData, null);
  HttpCaller("GET", "/Inventory/CategorySales", false, token, catsSaleshandleData, null);
  HttpCaller("GET", "/Inventory/CategoryPurchase", false, token, catsPurchasehandleData, null);
  HttpCaller("GET", "/Inventory/BrandSales", false, token, brandSaleshandleData, null);
  HttpCaller("GET", "/Inventory/BrandPurchase", false, token, brandPurchasehandleData, null);

}, []);
 const summaryoptions = {
  plugins: {
    title: {
      display: true,
      text: 'Last 30 Days Purchase, Sales and Inventory',
    },
  },
  responsive: true
};
  const summaryData = {
    labels : mydata.map((item)=>item.date),
    datasets: [
      {
        label: 'Sales Amount',
        data: mydata.map((item)=>item.sales),
        backgroundColor: 'rgb(255, 99, 132)',
      },
      {
        label: 'Inventory Amount',
        data:mydata.map((item)=>item.inventory),
        backgroundColor: 'rgb(226, 34, 191)',
      },
      {
        label: 'Purchase Amount',
        data: mydata.map((item)=>item.purchase),
        backgroundColor: 'rgb(53, 162, 235)',
      },
    ],
  };
       
// category wise products  and Sales
const categoryProdoptions = {
  plugins: {
    title: {
      display: true,
      text: 'Categorywise Products',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    }
  },
}
const categoryprodData = {
  labels : catProducts.map((item)=>item.title),
  datasets: [
    {
      label: 'Count',
      data: catProducts.map((item)=>item.count),
      backgroundColor: ['rgb(255, 99, 132)', 'rgb(232, 245, 50)', 'rgb(53, 162, 235)','rgb(226, 34, 191)'],
    }
  ],
}
const categorySalesoptions = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'Categorywise Sales Q.',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    }
  },
}
const categorySaleseData = {
  labels : catsSales.map((item)=>item.title),
  datasets: [
    {
      label: 'Quantity',
      data: catsSales.map((item)=>item.count),
      backgroundColor: ['rgb(255, 99, 132)', 'rgb(53, 162, 235)','rgb(226, 34, 191)'],
    }
  ],
}    
const categoryPurchaseoptions = {
  indexAxis: 'x',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'Categorywise Purachase Q.',
    },
  },
  responsive: true,
}
const categoryPurchaseData = {
  labels : catsPurchase.map((item)=>item.title),
  datasets: [
    {
      label: 'Quantity',
      data: catsPurchase.map((item)=>item.count),
      backgroundColor: ['rgb(255, 99, 132)', 'rgb(53, 162, 235)','rgb(226, 34, 191)'],
    }
  ],
}        
 


// Brand wise products and sales purchase
const brandPurchaseoptions = {
  plugins: {
    title: {
      display: true,
      text: 'Brand ywise Purachase Q.',
    },
  },
  responsive: true,
}
const brandPurchaseData = {
  labels : brandPurchase.map((item)=>item.title),
  datasets: [
    {
      label: 'Brand',
      fill: true,
      data: brandPurchase.map((item)=>item.count),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    }
  ],
} 
const brandSalesoptions = {
  plugins: {
    title: {
      display: true,
      text: 'Brand wise Sales Q.',
    },
  },
  responsive: true,
}
const brandSaleseData = {
  labels : brandSales.map((item)=>item.title),
  datasets: [
    {
      label: 'Brand',
      fill: true,
      data: brandSales.map((item)=>item.count),
      borderColor: 'rgb(232, 245, 50)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    }
  ],
} 
const brandProdoptions = {
  plugins: {
    title: {
      display: true,
      text: 'Brand wise Products',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    }
  },
}
const brandProdData = {
  labels : brandsProducts.map((item)=>item.title),
  datasets: [
    {
      label: 'Count',
      data: brandsProducts.map((item)=>item.count),
      backgroundColor: ['rgb(255, 99, 132)', 'rgb(53, 162, 235)','rgb(226, 34, 191)','rgb(232, 245, 50)'],
    }
  ],
}

    return (
        <>
        <h4>Dashboard</h4>
        <div className="d-md-flex">
          <div className="col-md-6" style={{height:300}}>
            <Barchart chartData={summaryData} chartoptions={summaryoptions} />
          </div>
          <div className="col-md-3" style={{height: 300}}>
            <PieChart chartData={categoryprodData} chartoptions={categoryProdoptions} />
          </div>
          <div className="col-md-3" style={{height: 300}}>
            <PieChart chartData={brandProdData} chartoptions={brandProdoptions} />
          </div>
        </div>
<hr/>

        <div className="d-md-flex justify-content-md-between">
          <div className="col-md-6">
            <LineChart chartData={brandPurchaseData} chartoptions={brandPurchaseoptions}></LineChart>
          </div>
          <div className="col-md-6">
          <LineChart chartData={brandSaleseData} chartoptions={brandSalesoptions}></LineChart>
          </div>
        </div>


        <hr/>
        <div className="d-md-flex justify-content-md-between">
          <div className="col-md-6">
          <Barchart chartData={categorySaleseData} chartoptions={categorySalesoptions} />
          </div>
          <div className="col-md-6">
          <Barchart chartData={categoryPurchaseData} chartoptions={categoryPurchaseoptions} />
          </div>
        </div>

        </>
    )
}