import { useEffect, useState } from 'react';
import useAuth from '../Components/AuthKey';
import { Card, CardBody, CardHeader } from '../Components/Card';
import { Table } from '../Components/Table';
import HttpCaller from '../Requester';
const PurchaseReport = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [selectedId, setSelection] = useState(null);
    const [Details, setDetails] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const token = useAuth();
    function HandleRequest() {
        if (this.readyState === 4) {
            if (this.status === 200) {
                setIsLoaded(true);
                setItems(JSON.parse(this.response));
            }

        }
    };
    function handleGetRequest() {
        if (this.readyState === 4) {
            if (this.status === 200) {
                setDetails(JSON.parse(this.response));
                console.log(JSON.parse(this.response));
                setIsShow(true);
            }
        }
    }
    function closeModel() {
        setIsShow(false);
    }
    function GetDetails(e) {
        let value = e.target.value;
        HttpCaller("GET", "/Purchase/Details?Id=" + value, false, token, handleGetRequest, null);
    }
    useEffect(() => {
        HttpCaller("GET", "Purchase/Get", false, token, HandleRequest, null);
    }, [])
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <>
                <Card>
                    <CardHeader>Purchase Record</CardHeader>
                    <CardBody>
                        <Table>
                            <thead>
                                <tr><th>SL</th><th>Supplier</th><th>Address</th><th>Date</th><th>Net Amount</th></tr>
                            </thead>
                            <tbody>
                                {items.length > 0 ? items.map((item, index) => { return (<tr key={index}><td>{index + 1}</td><td>{item.supplier}</td><td>{item.address}</td><td>{item.date}</td><td>{item.netAmount}</td><td><button type='button' value={item.id} className="btn btn-sm btn-default" onClick={GetDetails}>Items</button></td></tr>) }) : <tr><td colSpan="5">no items found</td></tr>}
                            </tbody>
                        </Table>
                    </CardBody>
                    
                </Card>


                <div className={`modal fade ${isShow === true ? "show d-block" : " "}`} data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="transferModelLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Purchase  Details Product</h5>
                                <button type="button" className="close" onClick={closeModel}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <table className='table table-hover'>
                                    <thead>
                                        <tr><th>Name</th><th>Quantity</th><th>Rate</th><th>Amount</th></tr>
                                    </thead>
                                    <tbody>
                                        {Details.length > 0 ? Details.map((item, index) => { return (<tr key={index}><td>{item.name}</td><td>{item.quantity}</td><td>{item.rate}</td><td>{item.amount}</td></tr>) }) : <tr><td colSpan="4">No Items Added to card</td></tr>}
                                    </tbody>
                                </table>

                            </div>

                            <div className="modal-footer">
                                <button type="button" onClick={closeModel} className="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {isShow === true ? <div className="modal-backdrop fade show"></div> : <div></div>}
            </>
        )
    }
}

export default PurchaseReport;