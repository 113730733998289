import {
  ArcElement, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
  
  ChartJS.register(
    Title,
    LinearScale,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );
  
  export const options = {
    legend: {
      position: 'top',
    },
    plugins: {
      title: {
        display: true,
        text: ' ',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
const  LineChart=({chartData, chartoptions})=>{
    return (
        <>
        <Line data={chartData} options={chartoptions ?? options}></Line>
        </>
    )
} 
export default LineChart;