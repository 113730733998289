import { useEffect, useState } from 'react';
import useAuth from '../Components/AuthKey';
import { Select, Submit } from "../Components/Form";
import HttpCaller from '../Requester';
const Purchase = () => {
    const token = useAuth();
    const [stores, setStores] = useState([]);
    const [products, setProducts] = useState([]);
    const [purcuahse, setPurchase] = useState({ Supplier: "", PurchaseDate: "", Address: "", StoreId: "0", Notes: "" });
    const [Details, SetDetails] = useState([]);
    const [item, setItem] = useState({ ProductId: "0", Quantity: "0", Rate: "0", Amount: "0" });
    const [rate, setRate] = useState("0");
    const handleChange = e => {
        const { name, value } = e.target;
        setPurchase(prevState => ({
            ...prevState,
            [name]: value
        }));
    }



    function HandleSubmit() {
        if (this.readyState === 4) {
            if (this.status === 200) {
               SetDetails([]);
            }
        }
    };
    const SubmitHandler = (e) => {
        e.preventDefault();
        var data = {
            Purchase: purcuahse,
            Details: Details
        }
        HttpCaller("POST", "Purchase/PurchaseRcord", true, token, HandleSubmit, JSON.stringify(data));
       

    }


    useEffect(() => {
        HttpCaller("GET", "Stores/Get", false, token, handleStoreData, null);
        function handleStoreData() {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    setStores(JSON.parse(this.response));
                }

            }
        }
        HttpCaller("GET", "Products/Get", false, token, handleProductData, null);
        function handleProductData() {
            if (this.readyState === 4) {
                if (this.status === 200) {
                    setProducts(JSON.parse(this.response));
                }
            }
        }

    }, [])

    let ProductChange = (e) => {
        const { name, value } = e.target;
        var pro = products.find(f => f.id == value);
        console.log(pro);
        setItem(prevState => ({
            ...prevState,
            ["ProductId"]: value,
            ["Name"]: pro.title ,
            ["Rate"]: pro.price
        }));
      //  setRate(pro.rate);
    }

    let fieldChanger = (e) => {
        const { name, value } = e.target;
        setItem(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    let Calculate = () => {
        var am = item.Rate * item.Quantity;
        setItem(prevState => ({
            ...prevState, ["Amount"]: am
        }));
    }
    let AddItem = () => {

        SetDetails(prevState => (
            [...prevState, item]
        ));
    }

    return (
        <>
            <form onSubmit={SubmitHandler} method="POST">
                <div className='col-md-8 offset-md-2'>
                    <h4>Purchase Items</h4>
                    <hr />
                    <div className="d-md-flex justify-content-md-between">
                        <div className="col-md-4 pl-md-0">
                            <span className="control-label">Supplier Name</span>
                            <input name="Supplier" type="text" onChange={handleChange} className="form-control" />
                        </div>
                        <div className="col-md-4">

                            <span className="control-label">Date</span>
                            <input name="PurchaseDate" onChange={handleChange} type="date" className="form-control" />
                        </div>
                        <div className="col-md-4 pr-md-0">
                            <span className="control-label">Address</span>
                            <input name="Address" onChange={handleChange} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="d-md-flex justify-content-md-between">
                        <div className="col-md-4 pl-md-0">
                            <span className="control-label">Store</span>
                            <Select items={stores} id="storeId" name="StoreId" handler={handleChange}>
                                <option>--select store--</option>
                            </Select>

                        </div>
                        <div className="col-md-8 pr-md-0">
                            <span className="control-label">Notes</span>
                            <textarea name="Notes" onChange={handleChange} rows="2" cols="20" className="form-control"></textarea>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <Select items={products} name="ProductId" id="productId" handler={ProductChange}>
                            <option>--Select Product--</option>
                        </Select>
                        <input type="number" value={item.Quantity} onChange={fieldChanger} title="Quantity" min="0" name='Quantity'></input>
                        <input type="number" value={item.Rate} onChange={fieldChanger} name="Rate"/>
                        <button type='button' onClick={AddItem} onMouseOver={Calculate} className="btn  btn-sm btn-primary">Add</button>
                    </div>
                    <table className='table table-hover'>
                        <thead>
                            <tr><th>Name</th><th>Quantity</th><th>Rate</th><th>Amount</th></tr>
                        </thead>
                        <tbody>
                            {Details.length > 0 ? Details.map((item, index) => { return (<tr key={index}><td>{item.Name}</td><td>{item.Quantity}</td><td>{item.Rate}</td><td>{item.Amount}</td></tr>) }) : <tr><td colSpan="4">No Items Added to card</td></tr>}
                        </tbody>
                    </table>
                    <div className="clearfix text-right">
                        <Submit value="Purchase"></Submit>
                    </div>
                </div>
            </form>
            <div className='clearfix'>
                <a href='/purchaseReports'>Back to Reports</a>
            </div>
        </>
    )
}

export default Purchase;