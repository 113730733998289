import { useEffect, useState } from 'react';
import useAuth from '../Components/AuthKey';
import { Card, CardBody, CardHeader } from '../Components/Card';
import { Table } from '../Components/Table';
import HttpCaller from '../Requester';
function Stores() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const token = useAuth();
    function storesDataHandler() {
        if (this.readyState === 4) {
            if (this.status === 200) {
                setIsLoaded(true);
                setItems(JSON.parse(this.response));
            }
        }
    };
    useEffect(() => {
        HttpCaller("GET", "/Stores/Get", false, token, storesDataHandler, null);
    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <>
                <Card>
                    <CardHeader>Stores</CardHeader>
                    <CardBody>
                        <Table>
                            <thead>
                                <tr><th>Id</th><th>	Name</th><th>Address</th><th>Description</th></tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => { return (<tr key={index}><td>{item.id}</td><td>{item.name}</td><td>{item.address}</td><td>{item.description}</td></tr>); })}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>


            </>
        )
    }
}

export default Stores;